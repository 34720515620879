import request from '@utils/request'

export const getListAPI = (params = {}) => {
  return request.get('/exchange-room/getListOfPage', { params })
}

export const getDetailAPI = (params = {}) => {
  return request.get('/exchange-room/getById', { params })
}

export const submitAPI = (params = {}) => {
  return request.post('/exchange-room/submitExchange', params)
}

export const calculateFeeAPI = (params = {}) => {
  return request.post('/exchange-room/calculateFee', params)
}

export const getLastRentAPI = (params = {}) => {
  return request.get('/exchange-room/getLastRent', {params})
}

export const getHouseListAPI = (params = {}) => {
  return request.post('/contract/houseList', params)
}

export const getQueryWaterElectricityAPI = (data = {}) => {
  return request.post('/exchange-room/queryWaterElectricity', data)
}
